import get from 'lodash/get';
import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const airtableData =
    action?.worldChangingIdeasPage?.metadata?.records?.[0]?.fields ||
    action?.worldChangingIdeasPage?.data?.Metadata?.[0] ||
    {};
  const wpTitle = get(action, 'worldChangingIdeasPage.title.rendered');
  const img =
    airtableData?.['SEO image'] ||
    get(
      action,
      'worldChangingIdeasPage._embedded.wp:featuredmedia[0].source_url'
    );
  const wpExcerpt =
    get(action, 'worldChangingIdeasPage.acf.seoDescription') ||
    get(action, 'worldChangingIdeasPage.excerpt.rendered');
  const socialTitle =
    airtableData?.PageTitle || wpTitle ? striptags(wpTitle).trim() : ''; // displays for twitter and social platforms
  const seoTitle =
    airtableData?.SEOTitle ||
    get(action, 'worldChangingIdeasPage.acf.title') ||
    get(action, 'worldChangingIdeasPage.title.rendered'); // displays on google and browser tab
  const twitterDescription = airtableData?.twitterDescription;
  const description =
    airtableData?.metaDescription ||
    (wpExcerpt ? striptags(wpExcerpt).trim() : '');
  const url =
    airtableData?.PageUrl ||
    get(action, 'worldChangingIdeasPage.acf.publicUrl');

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle,
    overrideTitle: true,
    url,
    description,
    socialDescriptionOverride: description,
    twitterDescription,
    image: img,
    articleSection: 'World Changing Ideas',
    config: get(state, 'config'),
    facebookTitleOverride: socialTitle,
    twitterTitleOverride: socialTitle
  });
}
