import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let PageComponent = false;
let PageComponent2022 = false;
let PageComponent2023 = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  PageComponent = require('./nbtt_page').default;
  // eslint-disable-next-line global-require
  PageComponent2023 = require('./nbtt_page_2023').default;
  // eslint-disable-next-line global-require
  PageComponent2022 = require('./nbtt_page_2022').default;
}

export function nbttListPage(store) {
  const routeConfig = {
    path: '/:slug(next-big-things-in-tech/list)',
    component() {
      if (PageComponent) {
        return <PageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: PageComponent ? PageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./nbtt_page').then(resp => {
        PageComponent = resp.default;
        routeConfig.loadData = PageComponent.loadData(store);

        return PageComponent;
      });
    },
    chunk: 'nbttListpage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function nbttListPage2023(store) {
  const routeConfig = {
    path: '/:slug(next-big-things-in-tech/2023)',
    component() {
      if (PageComponent2022) {
        return <PageComponent2023 />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: PageComponent2023 ? PageComponent2023.loadData(store) : false,
    preloadChunk() {
      return import('./nbtt_page_2023').then(resp => {
        PageComponent2023 = resp.default;
        routeConfig.loadData = PageComponent2023.loadData(store);

        return PageComponent2023;
      });
    },
    chunk: 'nbttListpage2023'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function nbttListPage2022(store) {
  const routeConfig = {
    path: '/:slug(next-big-things-in-tech/2022)',
    component() {
      if (PageComponent2022) {
        return <PageComponent2022 />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: PageComponent2022 ? PageComponent2022.loadData(store) : false,
    preloadChunk() {
      return import('./nbtt_page_2022').then(resp => {
        PageComponent2022 = resp.default;
        routeConfig.loadData = PageComponent2022.loadData(store);

        return PageComponent2022;
      });
    },
    chunk: 'nbttListpage2022'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
