import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const year = get(state, 'micYear.year');
  const imgPresets =
    'https://images.fastcompany.net/image/upload/w_530,q_auto,f_auto,fl_lossy';

  const airtableMetaData = action?.micYearPage?.metadata?.[0];

  const buildCustomConfig = currentYear => {
    switch (currentYear) {
      case 'list':
        return {
          overrideTitle: true,
          title: airtableMetaData?.['SEO title'],
          url: `https://www.fastcompany.com/most-innovative-companies/${year}`,
          description: airtableMetaData?.['SEO description'],
          facebookTitleOverride: airtableMetaData?.twitterTitle,
          twitterTitleOverride: airtableMetaData?.twitterTitle,
          twitterDescription: airtableMetaData?.twitterDescription,
          socialDescriptionOverride: airtableMetaData?.['SEO description'],
          image: airtableMetaData?.['SEO image']
        };

      case '2023':
        return {
          title: `The World’s Most Innovative Companies of ${year}`,
          url: `https://www.fastcompany.com/most-innovative-companies/${year}`,
          description: `Fast Company's annual ranking of the World's Most Innovative Companies covers 54 industries and sectors, from advertising to workplace.`,
          image: `https://assets.fastcompany.com/image/upload/w_970/v1677686431/wp-cms/uploads/2023/02/MIC_TypeSocialShare.jpg`
        };

      case '2022':
        return {
          title: `The World’s Most Innovative Companies of ${year}`,
          url: `https://www.fastcompany.com/most-innovative-companies/${year}`,
          description: `Fast Company’s annual ranking of businesses that are making an outsize impact`,
          image: `${imgPresets}/v1646681187/fcweb/p-3-fast-company-mic-2022-list-1_konkmv.jpg`
        };

      default:
        return {
          title: `The World’s 50  Most Innovative Companies of ${year}`,
          url: `https://www.fastcompany.com/most-innovative-companies/${year}`,
          description: `Our annual guide to the businesses that matter the most`,
          image: `${imgPresets}/v1550783002/fcweb/MIC_WEB_OpenerGraphic_16x9_hroirl.jpg`
        };
    }
  };

  const defaultConfig = {
    site,
    siteConf,
    articleSection: 'Most Innovative Companies',
    config: state.config
  };

  const customConfig = buildCustomConfig(year);

  const finalConfig = Object.assign({}, defaultConfig, customConfig);

  return baseSeoUtil(finalConfig);
}
