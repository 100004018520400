import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';
import striptags from 'striptags';

const CURRENT_LATEST_YEAR = '2024';

// DEFAULT LIST PAGE
const queer50List = (state = {}, action = {}) => {
  const q50 = action?.queer50;
  const modernQ50Data = get(
    q50,
    'externalList.metadata.[0]',
    get(q50, 'externalList.Metadata.[0]', {})
  );
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const rss = `/rss`;
  const seoTitle = modernQ50Data?.['SEO Title'] || q50?.acf?.title;
  const pageTitle = modernQ50Data?.Name || q50?.title?.rendered;
  const defaultTitle = modernQ50Data?.Name;
  const franchiseYear =
    q50?.franchiseCategory?.replace('q50-', '') ||
    q50?.acf?.franchise_year ||
    CURRENT_LATEST_YEAR;
  const pageExcerpt = modernQ50Data?.SubHed || q50?.excerpt?.rendered;
  const defaultExcerpt =
    'The annual list of LGBTQ women and nonbinary innovators in business and tech';
  const pageImage =
    modernQ50Data?.['Share Image'] ||
    q50?.['_embedded.wp:featuredmedia']?.[0].source_url;
  const franchiseImage =
    modernQ50Data?.['featureImage (desktop)'] ||
    q50?.acf?.franchise_images?.franchise_hero_image_desktop;
  const defaultImage =
    'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2022/05/Fast_Company-Final_v02-copy2.jpg';

  const base = {
    site,
    siteConf,
    title: seoTitle || pageTitle || defaultTitle,
    url: `https://www.${site}.com/queer-50/${
      franchiseYear === CURRENT_LATEST_YEAR ? 'list' : franchiseYear
    }`,
    description:
      modernQ50Data?.['SEO Meta Description'] ||
      striptags(pageExcerpt) ||
      defaultExcerpt,
    socialDescriptionOverride: modernQ50Data?.['OG Description'],
    image: pageImage || franchiseImage || defaultImage,
    articleSection: '',
    rss,
    config: state.config,
    overrideTitle: true,
    extend: [
      {
        link: []
      }
    ]
  };

  return baseSeoUtil(base);
};

// INDIVIDUAL LIST ITEM PAGE
const queer50Item = (state = {}, action = {}) => {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const rss = `/rss`;
  const pageExcerpt = get(action, 'queer50.excerpt.rendered');
  const defaultExcerpt =
    'The second annual list of LGBTQ women and nonbinary innovators in business and tech';
  const itemRank = get(action, 'queer50.itemRank');
  const franchiseYear = get(
    action,
    'queer50.acf.franchise_year',
    CURRENT_LATEST_YEAR
  );
  const thisItem = get(
    action,
    `queer50.externalList.List[${parseInt(itemRank, 10) - 1}]`,
    get(
      action,
      `queer50.externalList['Table 1'][${parseInt(itemRank, 10) - 1}]`
    )
  );
  const title = `${thisItem.Name} is No. ${
    thisItem.Rank
  } on the ${franchiseYear} Fast Company Queer 50 list`;
  const pageImage = get(
    action,
    'queer50._embedded.wp:featuredmedia[0].source_url'
  );
  const franchiseImage = get(
    action,
    'queer50.acf.franchise_images.franchise_hero_image_desktop'
  );
  const defaultImage =
    'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2022/05/Fast_Company-Final_v02-copy2.jpg';

  const base = {
    site,
    siteConf,
    title,
    url: itemRank
      ? `https://www.${site}.com/queer-50/list/rank/${itemRank}`
      : `https://www.${site}.com/queer-50/list`,
    description: striptags(pageExcerpt) || defaultExcerpt,
    image: pageImage || franchiseImage || defaultImage,
    articleSection: '',
    rss,
    config: state.config,
    extend: [
      {
        link: []
      }
    ]
  };

  return baseSeoUtil(base);
};

// INITIAL FRANCHISE YEAR LOGIC - 2021
const queer50Initial = (state = {}) => {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const description =
    'The second annual list of LGBTQ women and nonbinary innovators in business and tech';
  const title = 'Announcing Fast Company’s second annual Queer 50 list';
  const rss = `/rss`;

  const base = {
    site,
    siteConf,
    title,
    url: `https://www.${site}.com/queer-50/2021`,
    description,
    image:
      'https://assets.fastcompany.com/image/upload/v1590670864/wp-cms/uploads/2020/05/FC-Q50-LogoAnim_16x9_still.webp',
    articleSection: '',
    rss,
    config: state.config,
    extend: [
      {
        link: []
      }
    ]
  };

  return baseSeoUtil(base);
};

export { queer50List, queer50Item, queer50Initial };
