import get from 'lodash/get';
import loadable from '@loadable/component';

const WebLayout = loadable(() =>
  import('../views/containers/layouts/web_layout/web_layout')
);
const BlankLayout = loadable(() =>
  import('../views/containers/layouts/blank_layout/blank_layout')
);
import homepageRoute from '../views/containers/pages/home_page/homepage_route';
import allRoute from '../views/containers/pages/home_page/homepage_all_route';
import allIndexRoute from '../views/containers/pages/home_page/homepage_all_index_route';
import authorRoute from '../views/containers/pages/author_page/routes/author_route';
import categoryRoute from '../views/containers/pages/category_page/routes/category_route';
import categoryPaginatedRoute from '../views/containers/pages/category_page/routes/category_paginated_route';
import magazineCategoryRoute from '../views/containers/pages/category_page/routes/magazine_category_route';
import htmlSitemapIndexRoute, {
  sitemapFranchises,
  sitemapHubs
} from '../views/containers/pages/html_sitemap_index_page/html_sitemap_index_route';
import htmlSitemapYearRoute from '../views/containers/pages/html_sitemap_index_page/html_sitemap_year_route';
import htmlSitemapPostRoute from '../views/containers/pages/html_sitemap_post_page/html_sitemap_post_route';
import wpPageRoute from '../views/containers/pages/wp_page/routes/wp_page_route';
import fcExecutiveBoardRoute from '../views/containers/pages/tag_page/routes/fc_executive_board_route';
import tagPageRoute from '../views/containers/pages/tag_page/routes/tag_page_route';
import myAccountPageRoute from '../views/containers/pages/my_account_page/routes/my_account_route';
import loginPageRoute from '../views/containers/pages/login_page/routes/login_route';
import subscribePageRoute from '../views/containers/pages/subscribe_page/routes/subscribe_route';
import groupSubscriptionsRoute from '../views/containers/pages/group_subscriptions/group_subscriptions_route';
import subscriptionsSuccessRoute from '../views/containers/pages/group_subscriptions/success/subscriptions_success_route';
import offerPageRoute from '../views/containers/pages/offer_page/routes/offer_route';
import specificSubscriptionRoute from '../views/containers/pages/offer_page/routes/specific_subscription_route';
import sharedOfferPageRoute from '../views/containers/pages/offer_page/routes/shared_offer_route';
import logoutPageRoute from '../views/containers/pages/logout_page/routes/logout_route';
import registerPageRoute from '../views/containers/pages/register_page/routes/register_route';
import resetPasswordPageRoute from '../views/containers/pages/reset_password_page/routes/reset_password_route';
import podcastPageRoute from '../views/containers/pages/podcast_page/routes/podcast_page_route';
import brandworksRoute from '../views/containers/pages/tag_page/routes/brandworks_route';
import thorntonRoute from '../views/containers/pages/tag_page/routes/thornton-route';
import premiumTagRoute from '../views/containers/pages/tag_page/routes/premium_tag_route';
import facebookRoute from '../views/containers/pages/tag_page/routes/facebook-route';
import innovationFestivalRoute from '../views/containers/pages/tag_page/routes/innovation_festival_route';
import innovationFestival360Route from '../views/containers/pages/tag_page/routes/innovation_festival_360_route';
import citrixBrandPageRoute from '../views/containers/pages/tag_page/routes/citrix_brand_route';
import magazineTagPageRoute from '../views/containers/pages/tag_page/routes/magazine_tag_page_route';
import {
  defaultBwiPage,
  modernBwiPage,
  bwi2023Page,
  bwiListPage
} from '../views/containers/pages/bwi/best_workplaces_page_route';
import {
  wciListPage,
  wciListPage2023,
  wciListPageLegacy
} from '../views/containers/pages/wci/world_changing_ideas_page_route';
import verifySubscriptionPageRoute from '../views/containers/pages/verify_subscription_page/routes/verify_subscription_route';

/* Franchise List Pages Start */

import creator25ListPage from '../views/containers/pages/list_pages/creator25_page_route';
import {
  nbttListPage,
  nbttListPage2023,
  nbttListPage2022
} from '../views/containers/pages/list_pages/nbtt_page_route';
import {
  micModernLegacy,
  micPageModern,
  micPage2024,
  micPage2025
} from '../views/containers/pages/list_pages/mic_year_modern_route';

/* Franchise List Pages End */

import brandsThatMatterPage from '../views/containers/pages/brands_that_matter/brands_that_matter_page_route';
import franchisePage from '../views/containers/pages/franchise_page/franchise_page_route';
import innovationByDesignRoute from '../views/containers/pages/innovation_by_design/route';
import {
  ibd2019Page,
  ibd2018Page,
  legacyIbdPage,
  defaultIbdPage,
  anniversaryIbdPage
} from '../views/containers/pages/ibd/innovation_by_design_page/ibd_route';
import {
  ibd2018CategoryPage,
  legacyIbdCategoryPage
} from '../views/containers/pages/ibd/innovation_by_design_page/ibd_category_route';
import ibdCompanyPage from '../views/containers/pages/ibd/innovation_by_design_page/ibd_company_route';
import {
  mcp2018Page,
  mcp2019Page,
  mcp2021Page,
  mcp2022Page,
  legacyMcpPage,
  defaultMcpPage
} from '../views/containers/pages/mcp/most_creative_people_page/most_creative_people_route';
import {
  micPage2018,
  micPage2019,
  micPage2020,
  legacyMicPage
} from '../views/containers/pages/mic_legacy/mic_year_page/routes/mic_year_route';
import postRoute from '../views/containers/pages/post_page/routes/post_route';
import bestWorkplacesRoute from '../views/containers/pages/post_page/routes/best_workplaces_route';
import mostCreativePeoplePostRoute from '../views/containers/pages/post_page/routes/most_creative_people_route';
import innovationByDesignPostRoute from '../views/containers/pages/post_page/routes/innovation_by_design_post_route';
import postSectionRoute from '../views/containers/pages/post_page/routes/post_section_route';
import postPaginatedSlideshowRoute from '../views/containers/pages/post_page/routes/post_paginated_slideshow_route';
import postPreviewRoute from '../views/containers/pages/post_page/routes/post_preview_route';
import postClientPreviewRoute from '../views/containers/pages/post_page/routes/post_client_preview_route';
import postPreviewSectionRoute from '../views/containers/pages/post_page/routes/post_preview_section_route';
import notFoundRoute from '../views/containers/pages/not_found_page/not_found_route';
import videoRoute from '../views/containers/pages/video/video_page/video_route';
import playlistIndexRoute from '../views/containers/pages/video/playlist_index_page/video_playlist_index_route';
import videoIndexRoute from '../views/containers/pages/video/video_index_page/video_index_route';
import playlistRoute from '../views/containers/pages/video/playlist_page/video_playlist_page_route';
import newslettersRoute from '../views/containers/pages/newsletters/newsletters_page/newsletters_route';
import modernCeoRoute from '../views/containers/pages/newsletters/modern_ceo/modern_ceo_route';
import categoryTagRoutes from '../views/containers/pages/category_page/routes/category_tag_route';
import { queer50List } from '../views/containers/pages/queer50/routes/queer50_route';
import {
  mcpHubPage,
  micHubPage,
  wciHubPage,
  defaultHubPage
} from '../views/containers/pages/hub_pages/hub_page_route';
import micIxPage from '../views/containers/pages/one_off_pages/one_off_pages_route';
import recognitionHubPage from '../views/containers/pages/recognition_hub/recognition_hub_route';
import franchiseApplyPageRoute from '../views/containers/pages/applications/franchise_apply_page_route';
import mindEdgeRoute from '../views/containers/pages/mindedge/mindedge_route';

export default function getRoutesWithStore(store) {
  const state = store.getState();
  const isBlank = get(state, 'config.isBlank');
  const featureFlags = get(state, 'config.featureFlags', {});
  const routeConfig = [
    {
      component: isBlank ? BlankLayout : WebLayout,
      routes: [
        defaultHubPage(store),
        mcpHubPage(store),
        micHubPage(store),
        wciHubPage(store),
        micIxPage(store),
        recognitionHubPage(store),
        creator25ListPage(store),
        nbttListPage(store),
        nbttListPage2023(store),
        nbttListPage2022(store),
        micPageModern(store),
        micPage2024(store),
        micPage2025(store),
        micModernLegacy(store),
        franchiseApplyPageRoute(store),
        queer50List(store),
        videoRoute(store),
        videoIndexRoute(store),
        playlistIndexRoute(store),
        playlistRoute(store),
        allIndexRoute(store),
        allRoute(store),
        wpPageRoute(store),
        newslettersRoute(store),
        modernCeoRoute(store),
        htmlSitemapIndexRoute(store),
        htmlSitemapYearRoute(store),
        htmlSitemapPostRoute(store),
        sitemapFranchises(store),
        sitemapHubs(store),
        facebookRoute(store),
        innovationFestivalRoute(store),
        fcExecutiveBoardRoute(store),
        innovationFestival360Route(store),
        tagPageRoute(store),
        verifySubscriptionPageRoute(store),
        myAccountPageRoute(store),
        registerPageRoute(store),
        loginPageRoute(store),
        subscribePageRoute(store),
        groupSubscriptionsRoute(store),
        subscriptionsSuccessRoute(store),
        offerPageRoute(store),
        specificSubscriptionRoute(store),
        sharedOfferPageRoute(store),
        logoutPageRoute(store),
        resetPasswordPageRoute(store),
        podcastPageRoute(store),
        brandworksRoute(store),
        thorntonRoute(store),
        premiumTagRoute(store),
        citrixBrandPageRoute(store),
        ...magazineTagPageRoute(store),
        innovationByDesignPostRoute(store),
        ibdCompanyPage(store),
        ibd2018CategoryPage(store),
        ibd2019Page(store),
        legacyIbdCategoryPage(store),
        ibd2018Page(store),
        legacyIbdPage(store),
        defaultIbdPage(store),
        anniversaryIbdPage(store),
        innovationByDesignRoute(store), // 2024 onwards
        mostCreativePeoplePostRoute(store),
        mcp2018Page(store),
        mcp2019Page(store),
        mcp2021Page(store),
        mcp2022Page(store),
        legacyMcpPage(store),
        defaultMcpPage(store),
        micPage2019(store),
        micPage2018(store),
        legacyMicPage(store),
        authorRoute(store),
        postPreviewRoute(store),
        postClientPreviewRoute(store),
        postPreviewSectionRoute(store),
        franchisePage(store),
        magazineCategoryRoute(store),
        categoryPaginatedRoute(store),
        mindEdgeRoute(store),
        categoryRoute(store),
        ...categoryTagRoutes(store),
        bwi2023Page(store),
        bwiListPage(store),
        bestWorkplacesRoute(store),
        modernBwiPage(store),
        defaultBwiPage(store),
        wciListPage(store),
        wciListPage2023(store),
        wciListPageLegacy(store),
        brandsThatMatterPage(store),
        postRoute(store),
        postSectionRoute(store),
        postPaginatedSlideshowRoute(store),
        homepageRoute(store),
        notFoundRoute(store)
      ]
    }
  ];

  if (featureFlags.mic2020) {
    routeConfig[0].routes.unshift(micPage2020(store));
  }

  return routeConfig;
}
