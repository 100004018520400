import React from 'react';
import loadable from '@loadable/component';

import LoadingSpinner from '../../../components/loading_spinner/loading_spinner';

let MicModernLegacyComponent = false;
let MicModernComponent = false;
let Mic2024 = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  MicModernLegacyComponent = require('./mic_year_modern_legacy_page').default;
  // eslint-disable-next-line global-require
  MicModernComponent = require('../list_landers/mic-2023').default;
  // eslint-disable-next-line global-require
  Mic2024 = require('./mic_year_modern_page').default;
}

/**
 * MODERN LIST LANDER ROUTE
 * This route and component will always be used for the new modern page
 */
export function micPage2025(store) {
  const routeConfig = {
    path: '/most-innovative-companies/list/:rank?',
    component() {
      if (Mic2024) {
        return <Mic2024 />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mic2024 ? Mic2024.loadData(store, 'list') : false,
    preloadChunk() {
      return import('./mic_year_modern_page').then(resp => {
        Mic2024 = resp.default;
        routeConfig.loadData = Mic2024.loadData(store);
        return Mic2024;
      });
    },
    chunk: 'micPageModern'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

/**
 * MODERN LIST LANDER ROUTE
 * This route and component will always be used for the new modern page
 */
export function micPage2024(store) {
  const routeConfig = {
    path: '/most-innovative-companies/2024/:rank?',
    component() {
      if (Mic2024) {
        return <Mic2024 year="2024" />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mic2024 ? Mic2024.loadData(store, '2024') : false,
    preloadChunk() {
      return import('./mic_year_modern_page').then(resp => {
        Mic2024 = resp.default;
        routeConfig.loadData = Mic2024.loadData(store);
        return Mic2024;
      });
    },
    chunk: 'micPageModern'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function micPageModern(store) {
  const routeConfig = {
    path: '/most-innovative-companies/2023/:rank?',
    component() {
      if (MicModernComponent) {
        return <MicModernComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: MicModernComponent
      ? MicModernComponent.loadData(store, '2023')
      : false,
    preloadChunk() {
      return import('../list_landers/mic-2023').then(resp => {
        MicModernComponent = resp.default;
        routeConfig.loadData = MicModernComponent.loadData(store);
        return MicModernComponent;
      });
    },
    chunk: 'micPageModern'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

/**
 * MODERN Legacy LIST LANDER ROUTE
 * There is no way at this time to pass in the paths year to load data, so I need to seperate out the routes and update years manually. There will be a lot of copy and paste - B.C.
 * Intended Implementation for this is whenever we have a new MIC page all older pages will default to this route and this page. So 2023 will eventualy be moved to this route then 2024,2025,2026..
 */
export function micModernLegacy(store) {
  const routeConfig = {
    path: '/most-innovative-companies/:year(2022)',
    component() {
      if (MicModernLegacyComponent) {
        return <MicModernLegacyComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: MicModernLegacyComponent
      ? MicModernLegacyComponent.loadData(store, '2022')
      : false,
    preloadChunk() {
      return import('./mic_year_modern_legacy_page').then(resp => {
        MicModernLegacyComponent = resp.default;
        routeConfig.loadData = MicModernLegacyComponent.loadData(store);
        return MicModernLegacyComponent;
      });
    },
    chunk: 'micListPageModernLegacy'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
